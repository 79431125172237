<template>
  <div>
    <div
      v-if="isLoadingComplete"
      class="d-flex align-items-center"
    >
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <table-coupon
      v-show="!showForm"
      :coupons="tableData"
      @addCoupon="addCoupon($event)"
      @editCoupon="editCoupon($event)"
      @deleteCoupon="confirmDeleteCoupon($event)"
    />
    <form-coupon
      v-show="showForm"
      :action="action"
      @closeForm="closeForm"
      @actionSave="actionSave"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapMutations, mapState } from 'vuex'

import TableCoupon from '@/components/catalogs/coupon/TableCoupon.vue'
import FormCoupon from '@/components/catalogs/coupon/FormCoupon.vue'

export default {
  components: {
    BSpinner,
    TableCoupon,
    FormCoupon,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      action: '',
      searchTerm: '',
    }
  },

  computed: {
    ...mapState('coupon', ['coupons', 'isLoading']),
    tableData() {
      return this.coupons
    },
    isLoadingComplete() {
      return this.isLoading
    },
  },

  mounted() {
    this.searchCouponCatalog()
    this.searchCoupon()
  },

  methods: {
    ...mapActions('coupon',
      ['searchCoupon', 'createCoupon', 'deleteCoupon', 'updateCoupon']),
    ...mapActions('couponCatalog',
      ['searchCouponCatalog']),
    ...mapMutations('coupon', {
      setCoupon: 'setCoupon',
      restoreCoupon: 'restoreCoupon',
    }),
    addCoupon() {
      this.showForm = true
      this.restoreCoupon({ isUpdating: false })
      this.action = 'Create'
    },
    editCoupon(coupon) {
      this.setCoupon(coupon)
      this.showForm = true
      this.action = 'Edit'
    },
    closeForm() {
      this.showForm = false
    },
    actionSave(coupon) {
      if (this.action === 'Edit') {
        this.updateCoupon(coupon)
      }
      if (this.action === 'Create') {
        this.createCoupon(coupon)
      }
      this.showForm = false
    },
    async confirmDeleteCoupon(coupon) {
      const response = await this.$swal({
        title: `Are you sure to delete the coupon ${coupon.code}.?`,
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn Are you sure',
        },
      })
      if (response && response.value) {
        this.deleteCoupon(coupon)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
