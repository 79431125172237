<template>
  <b-card
    no-body
  >
    <div class="card-header">
      <b-card-title>
        {{ title }}
      </b-card-title>
      <feather-icon
        class="float-right cursor-pointer"
        size="21"
        icon="XIcon"
        @click="$emit('closeForm')"
      />
    </div>

    <template>
      <b-card-body>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-row>
                  <!-- Coupon Catalog -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Coupon Catalog</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="CouponCatalog"
                      >
                        <v-select
                          v-model="couponCopy.coupon_catalog_id"
                          label="name"
                          :state="errors.length > 0 ? false:null"
                          :options="couponCatalogs"
                          :reduce="couponCatalog => couponCatalog.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Status -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Status</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Status"
                      >
                        <b-form-input
                          v-model="couponCopy.status"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Status"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Code -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Code</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Code"
                      >
                        <b-form-input
                          v-model="couponCopy.code"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Code"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- submit button -->
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="validationForm"
                    >
                      Save
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </template>
  </b-card>
</template>

<script>

import { mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardBody, BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BFormInput,
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  props: {
    action: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      title: `${this.action} Coupon`,
      required,
    }
  },
  computed: {
    ...mapState('coupon', {
      coupon: 'coupon',
    }),
    ...mapState('couponCatalog', {
      couponCatalogs: 'couponCatalogs',
    }),
    couponCopy() {
      return { ...this.coupon }
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('actionSave', this.couponCopy)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
